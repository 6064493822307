










































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import UiRichText from '../UiRichText'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import { PropsData } from './UiSlider.types'
import objectFitImages from 'object-fit-images'
import rowspans from '../../directives/rowspans'
import RowspansElement from '../../directives/rowspans/rowspans.types'
const { inserted: insertRowspan, unbind: unbindRowspan } = rowspans

@Component({
  components: {
    UiRichText
  }
})
export default class UiSlider extends Vue {
  @Prop({ required: true }) propsData!: PropsData

  swiperInstance: Swiper | undefined
  currentIndex = 0
  isAutoplayEnabled = true
  progressAnimationHelper = false
  highlightedHeaders = [] as unknown as RowspansElement[]

  @Ref('swiper') swiper!: HTMLDivElement
  @Ref('image') image!: HTMLImageElement

  @Watch('currentIndex', { immediate: true })
  onCurrentIndexChanged (val: number) {
    if (val === 0 && this.isAutoplayEnabled) {
      this.progressAnimationHelper = false
      // Hacky way to restart css animation
      // Read more here: https://css-tricks.com/restart-css-animation/
      setTimeout(() => {
        this.progressAnimationHelper = true
      }, 100)
    }
  }

  initSwiper (): void {
    this.swiperInstance = new Swiper(this.swiper, {
      speed: 500,
      grabCursor: true,
      loop: true,
      autoplay: {
        delay: 5000
      },
      on: {
        slideChange: () => {
          if (typeof this.swiperInstance === 'undefined') return

          this.currentIndex = this.swiperInstance.realIndex
        },
        touchStart: () => {
          if (
            typeof this.swiperInstance === 'undefined' ||
            typeof this.swiperInstance.autoplay === 'undefined'
          ) {
            return
          }

          if (this.isAutoplayEnabled) {
            this.swiperInstance.autoplay.stop()
            this.isAutoplayEnabled = false
          }
        },
        init: () => {
          this.highlightedHeaders = (this.$refs.swiper as HTMLElement)?.querySelectorAll('.hightlighted-text') as unknown as RowspansElement[]
          if (this.highlightedHeaders && this.highlightedHeaders.length) {
            this.highlightedHeaders.forEach(header => insertRowspan(header))
          }
        }
      }
    })
  }

  goTo (index: string | number): void {
    if (
      typeof this.swiperInstance === 'undefined' ||
      typeof this.swiperInstance.autoplay === 'undefined'
    ) {
      return
    }

    this.swiperInstance.slideToLoop(parseInt(index.toString()))

    if (this.isAutoplayEnabled) {
      this.swiperInstance.autoplay.stop()
      this.isAutoplayEnabled = false
    }
  }

  mounted () {
    // This fixes an issue with incorrectly timed first
    // slide progress bar due to slide speed not being part
    // of the slide duration.
    if (this.propsData.slides.length > 1) {
      setTimeout(() => {
        this.initSwiper()
      }, 500)
    }

    objectFitImages(this.image)
  }

  beforeDestroy () {
    if (this.highlightedHeaders && this.highlightedHeaders.length) {
      this.highlightedHeaders.forEach(header => unbindRowspan(header))
    }
  }
}
